import { Priority } from '../../../utils/scheduler/types'
import { getAndStoreRelatedProducts } from 'theme/helpers';
import config from 'config';

export const HOME_BLOCKS = {
  hero: {
    id: 'hero',
    priority: Priority.CRITICAL,
    loadData: (store) => store.dispatch('promoted/updateHeadImage'),
    loadComponent: async () => {
      const module = await import('../components/organisms/o-home-hero.vue')
      return module.default
    }
  },
  'interesting-categories': {
    id: 'interesting-categories',
    priority: Priority.IMPORTANT,
    loadData: (store) => {
      return store.dispatch('interesting-categories/loadCategories')
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/widgets/o-home-interesting-categories.vue')
      return module.default
    }
  },
  'home-info-block': {
    id: 'home-info-block',
    priority: Priority.IMPORTANT,
    loadData: (store) => {
      return store.dispatch('cmsBlock/single', {
        value: 'home-info-block'
      })
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/widgets/o-home-info-block.vue')
      return module.default
    }
  },
  'bord-products': {
    id: 'bord-products',
    priority: Priority.IMPORTANT,
    loadData: (store) => {
      return store.dispatch('homepage/loadBordProduct')
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/carousel/bord-products.vue')
      return module.default
    }
  },
  'special-offers': {
    id: 'special-offers',
    priority: Priority.IMPORTANT,
    loadData: () => {
      return getAndStoreRelatedProducts(
        config.esputnik?.widgetIds?.productOffersMain,
        'productOffersMain',
        config?.entities?.product?.carouselSize,
        true
      )
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/o-home-special-offers.vue')
      return module.default
    }
  },
  'home-brands': {
    id: 'home-brands',
    priority: Priority.IMPORTANT,
    loadData: (store) => {
      return store.dispatch('brands/fetchBrandsList', {})
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/carousel/home-brands.vue')
      return module.default
    }
  },
  'home-special-categories': {
    id: 'home-special-categories',
    priority: Priority.IMPORTANT,
    loadData: (store) => {
      return store.dispatch('homepage/loadSampleSpecialCategories')
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/carousel/home-special-categories.vue')
      return module.default
    }
  },
  'popular-products': {
    id: 'popular-products',
    priority: Priority.IMPORTANT,
    loadData: () => {
      return getAndStoreRelatedProducts(
        config.esputnik?.widgetIds?.productPopularMain,
        'productPopularMain',
        100,
        true
      )
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/carousel/home-popular-products.vue')
      return module.default
    }
  },
  'home-cafe-carousel': {
    id: 'home-cafe-carousel',
    priority: Priority.IMPORTANT,
    loadData: () => {
      return getAndStoreRelatedProducts(
        config.esputnik?.widgetIds?.cafeProductsMain,
        'productCafeMain',
        config?.entities?.product?.queryMaxSize,
        true
      )
    },
    loadComponent: async () => {
      const module = await import('../components/organisms/o-home-cafe-carousel.vue')
      return module.default
    }
  },
  'advantages': {
    id: 'advantages',
    priority: Priority.NORMAL,
    loadData: (store) => {
      return store.dispatch('homepage/loadAdvantagesContent')
    },
    loadComponent: async () => {
      const module = await import('../components/molecules/m-advantages.vue')
      return module.default
    }
  }
}
